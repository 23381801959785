<ul class="nav">
  <li class="nav-item" [ngClass]="{'nav-highlight':curNav == i}"
    *ngFor="let item of nav;let i = index"
    (click)="navClick(i)">{{item}}</li>
</ul>
<div class="banner">
  <img src="assets/img/campus.png" alt="">
</div>
<div class="container campus">


  <div class="title" id="title0"><img src="assets/img/campus1.png" alt=""></div>
  <div class="star-container">
    <div class="star-item">
      <h3>项目简介</h3>
      <p>搭建技术人才成长的“快通道”，通过定制化的培养，着眼于未来，培养一批高水平的技术专家。</p>
    </div>
    <div class="star-item">
      <h3>学历专业要求</h3>
      <p>硕士、博士毕业；</p>
      <p>微电子、集成电路设计、电子工程、物理、数学、计算机、材料、通信、机械等专业</p>
    </div>
    <div class="star-item">
      <h3>选拔原则</h3>
      <p>GPA Top30%及以上；通过技术笔试测评；校招委员会集体评估。</p>
    </div>
  </div>


  <div class="title" id="title1"><img src="assets/img/campus2.png" alt=""></div>
  <ul class="nav type-nav">
    <li class="nav-item" [ngClass]="{'nav-highlight':curTypeNav == '数理基础类'}"
    (click)="changeNav('数理基础类')">数理基础类</li>
    <li class="nav-item" [ngClass]="{'nav-highlight':curTypeNav == '芯片设计类'}"
      (click)="changeNav('芯片设计类')">芯片设计类</li>
    <li class="nav-item" [ngClass]="{'nav-highlight':curTypeNav == '芯片工艺类'}"
      (click)="changeNav('芯片工艺类')">芯片工艺类</li>
      <li class="nav-item" [ngClass]="{'nav-highlight':curTypeNav == '软件类'}"
      (click)="changeNav('软件类')">软件类</li>
  </ul>
  <div class="jobs-container">
    <p-accordion *ngIf='!showEmpty'>
      <ng-container *ngFor="let job of jobs">
        <p-accordionTab *ngIf="job.info.type == curTypeNav">
          <ng-template pTemplate="header">
            <div class="p-accordion-header-text">
              <p>{{job.info.name}}</p>
              <span>{{job.info.city}}</span>
            </div>
            <button pButton pRipple type="button" label="立即投递" class="p-button-rounded"
            (click)="goToTD($event)"></button>
          </ng-template>
          <!-- <ng-template pTemplate="header">
            <button pButton pRipple type="button" label="立即投递" class="p-button-rounded"
            (click)="goToTD($event)"></button>
          </ng-template> -->
          <div class="job-description">
            <h3>职位描述</h3>
            <ol>
              <li *ngFor="let des of job?.description">
                {{des.listContent ? des.listText : des}}
                <ul *ngIf="des.listContent">
                  <li *ngFor="let deslist of des.listContent">- {{deslist}}</li>
                </ul>
              </li>
            </ol>
          </div>
          <div class="job-require">
            <h3>任职要求</h3>
            <ol>
              <li *ngFor="let req of job?.require">
                {{req.listContent ? req.listText : req}}
                <ul *ngIf="req.listContent">
                  <li *ngFor="let reqlist of req.listContent">- {{reqlist}}</li>
                </ul>
              </li>
            </ol>
          </div>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
    <div class="empty" *ngIf="showEmpty">
      <h2>未找到对应数据</h2>
    </div>
    <div class="job-nx">
      <h2>年薪范围</h2>
      <p>
        <span>
          <span class="label">本科:</span><span class="value">15-25万</span>
        </span>
        <span>
          <span class="label">硕士:</span><span class="value">20-40万</span>
        </span>
        <span>
          <span class="label">博士:</span><span class="value">30-50万</span>
        </span>
      </p>
    </div>
  </div>

  <div class="title" id="title2"><img src="assets/img/campus3.png" alt=""></div>
  <div class="qa-container">
    <div class="qa-item" (click)="goToRouter('star')">
      <img src="assets/img/campus-icon1.png" alt="">
      <span>明日之星计划</span>
    </div>
    <div class="qa-item" (click)="goToRouter('recruit')">
      <img src="assets/img/campus-icon2.png" alt="">
      <span>校园招聘</span>
    </div>
    <div class="qa-item" (click)="goToRouter('hire')">
      <img src="assets/img/campus-icon3.png" alt="">
      <span>录用和实习</span>
    </div>
  </div>

  <div class="title" id="title3"><img src="assets/img/campus4.png" alt=""></div>
  <app-senior></app-senior>
</div>
