import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  private interval:any;
  // private bannerAnimation:any;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit():void {
    const self = this;

    //animation
    // const banners = document.querySelectorAll('div[id*=banner_]');
    // const banner_ctrls = document.querySelectorAll('.banner-ctrl > span');
    // let banner_ctrlsArr = Array.apply(null, banner_ctrls);
    // if(banners.length){
    //   if (banner_ctrls.length) {
    //       let first_banner = banner_ctrls[0];
    //       self.toggleClass(first_banner, 'red-bgd', true);
    //   }
    //   self.interval = setInterval(()=>{self.bannerAnimation();}, 5000);
    // }


    // touch event
    // const banner_container = document.querySelector('.company-banner');
    // let touchstartPosiont:number;
    // banner_container.addEventListener('touchstart',(event:any)=>{
    //   touchstartPosiont = event.changedTouches[0].pageX;
    // });
    // banner_container.addEventListener('touchend',(event:any)=>{
    //   const touchendPosiont = event.changedTouches[0].pageX
    //   console.log(touchstartPosiont - touchendPosiont)
    //   if(touchstartPosiont - touchendPosiont > 50){
    //     // clearInterval(self.interval);
    //     // self.bannerAnimation();
    //     // self.interval = setInterval(()=>{self.bannerAnimation();}, 5000);
    //   }else if(touchstartPosiont - touchendPosiont < -50){

    //   }
    // });
  }

  ngOnDestroy():void {
    const self = this;
    clearInterval(self.interval);
  }

  goToRouter(url:string): void {
    const self = this;
    self.router.navigate([url]);
  }

  bannerAnimation():void {
    const self = this;

    let cur_move1 = document.querySelector('.banner-item[class~=banner_move_left1]');
    let cur_move2 = document.querySelector('.banner-item[class~=banner_move_left2]');
    let index:number;
    if (cur_move2 && cur_move1) {
      self.toggleClass(cur_move1, 'banner_move_left1', false);
      self.toggleClass(cur_move2, 'banner_move_left2', false);
      let id = Number(cur_move2.id.substr(-1, 1));
      index = id == 3 ? 1 : id + 1;
      self.toggleClass(cur_move2, 'banner_move_left1', true);
      self.toggleClass(document.getElementById('banner_' + index), 'banner_move_left2', true);
    }
    else {
      self.toggleClass(document.getElementById('banner_1'), 'banner_move_left1', true);
      self.toggleClass(document.getElementById('banner_2'), 'banner_move_left2', true);
      index = 2;
    }
    let active_ctrl = document.querySelector('.banner-ctrl > span.red-bgd');
    if (active_ctrl) {
      self.toggleClass(active_ctrl, 'red-bgd', false);
    }
    var target_ctrl = document.querySelector('.banner-ctrl > span[data-id=banner_' + index + ']');
    self.toggleClass(target_ctrl, 'red-bgd', true);
  }

  toggleClass(element:any, className:string, action:boolean):void {
    if (action) {
      if (!element.classList.contains(className)) {
        element.classList.add(className);
      }
    }
    else {
      if (element.classList.contains(className)) {
        element.classList.remove(className);
      }
    }
  }

  onBannerCtrl(event:any,dataId:string):void {
    const self = this;
    clearInterval(self.interval);
    let tar_banner = document.getElementById(dataId);
    let cur_move1 = document.querySelector('.banner-item[class~=banner_move_left1]');
    let cur_move2 = document.querySelector('.banner-item[class~=banner_move_left2]');
    if (tar_banner != cur_move2) {
      if (cur_move1 && cur_move2) {
        self.toggleClass(cur_move1, 'banner_move_left1', false);
        self.toggleClass(cur_move2, 'banner_move_left2', false);
      }
      if (!cur_move2) {
        cur_move2 = document.getElementById('banner_1');
      }
      self.toggleClass(cur_move2, 'banner_move_left1', true);
      self.toggleClass(tar_banner, 'banner_move_left2', true);
    }
    let active_ctrl = document.querySelector('.banner-ctrl > span.red-bgd');
    self.toggleClass(active_ctrl, 'red-bgd', false);
    self.toggleClass(event.target, 'red-bgd', true);
    self.interval = setInterval(()=>{self.bannerAnimation();}, 5000);
  }
}
