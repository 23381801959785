<div class="banner company-banner">
  <!-- <div class="banner-item" id="banner_1">
    <h1>Microelectronics Fabrication</h1>
    <span class="red-span">Predictable Success</span>
    <p>with computational models and data-driven processes</p>
  </div>
  <div class="banner-item" id="banner_2">
    <span class="red-span">More than Moore</span>
    <p>Multi-Physics Simulation, Modeling and Design</p>
  </div>
  <div class="banner-item" id="banner_3">
    <span class="red-span">Reach for the sky</span>
    <p>Modeling and Design Enablement for Specialty ICs</p>
  </div>
  <div class="banner-ctrl">
    <span data-id="banner_1" class="white-bgd" (click)="onBannerCtrl($event,'banner_1')"></span>
    <span data-id="banner_2" class="white-bgd" (click)="onBannerCtrl($event,'banner_2')"></span>
    <span data-id="banner_3" class="white-bgd" (click)="onBannerCtrl($event,'banner_3')"></span>
  </div> -->

  <div class="banner-item" id="banner_1">
    <h1>IC Manufacturing EDA with</h1>
    <span class="red-span">One-stop</span>
    <p>Solution Provider</p>
  </div>
  <ul class="icon-list">
    <li (click)="goToRouter('/product/opc')">
      <img src="assets/img/opc-icon.png" alt="OPC">
      <p>OPC</p>
    </li>
    <li (click)="goToRouter('/product/tcad')">
      <img src="assets/img/tCAD-icon.png" alt="TCAD">
      <p>TCAD</p>
    </li>
    <li (click)="goToRouter('/product/basic-ip')">
      <img src="assets/img/IP-icon.png" alt="IP" style="width:38px;">
      <p>基础IP</p>
    </li>
    <li (click)="goToRouter('/product/technology-development')">
      <img src="assets/img/service-icon.png" alt="technology">
      <p>工艺研发</p>
    </li>
  </ul>
</div>
<div class="container company">
  <h2>公司简介</h2>
  <p>成立于2018年的墨研计算，是一家致力于为晶圆厂提供生产制造全流程EDA软件及工艺研发服务综合解决方案的供应商。</p>
  <img src="assets/img/company.png" alt="">
  <p>通过自主开发和技术引进，墨研计算的产品线涵盖了光学邻近效应修正（OPC）、工艺器件仿真（TCAD）、器件建模（Device Model）、良率分析和提升（Yield Enhancement）、可制造性设计（DFM）在内的全系列制造类EDA软件产品。同时，墨研也为客户提供全流程工艺研发辅助服务和技术咨询，具体包括测试图形设计和良率分析、器件建模和PDK开发、基础和增值IP开发、设计工艺协同优化（DTCO）和研发流程自动化。</p>
  <p>墨研计算的核心团队由拥有20年以上的半导体行业经验的一线晶圆厂高管，以及国内最早从事此类软件研发的技术先驱组成。随着晶圆厂业务的快速增长，公司吸引了一批包括“中组部万人计划”专家、“江苏省双创人才”获得者在内的高层次人才，并组建了一支由50名科学家和工程师组成的国际化技术团队。计划到2022年底，公司将完成百名技术专家团的建设。</p>
  <p>2021年7月，墨研计算获得千万级融资，建设了高通量晶圆测试实验室、高性能仿真计算集群等研发和技术服务设施。凭借精深的EDA软件研发、应用能力及对晶圆厂工艺研发流程的深刻理解，墨研计算正快速成长为国内最全面的晶圆厂工艺平台研发解决方案供应商。</p>
</div>
