<div class="header">
  <div class="logo-box">
    <img src="assets/img/logo.png" alt="logo" (click)="goToRouter('/company')">
    <span>墨研计算科学（南京）有限公司</span>
  </div>
  <img src="assets/img/drop-down_icon.png" alt="nav-list" style="width:28px"
  (click)="toggleNav()">
</div>
<ul class="nav-list" *ngIf="showNav">
  <li [ngClass]="{'highlight': curNav=='company'}" (click)="goToRouter('/company')">公司介绍</li>
  <li [ngClass]="{'highlight': curNav=='product'}" (click)="goToRouter('/product')">公司产品</li>
  <li class="preitem">
    <div>我要应聘</div>
    <ul class="sublist">
      <li [ngClass]="{'highlight': curNav=='jobs'}" (click)="goToRouter('/jobs')">社会招聘</li>
      <li [ngClass]="{'highlight': curNav=='life'}" (click)="goToRouter('/life')">生活福利</li>
    </ul>
  </li>
  <li [ngClass]="{'highlight': curNav=='news'}" (click)="goToRouter('/news')">最新消息</li>
  <li [ngClass]="{'highlight': curNav=='contact'}" (click)="goToRouter('/contact')">联系地址</li>
</ul>
<div class="router-outlet">
  <router-outlet></router-outlet>
</div>
