import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showNav:boolean = false;
  curNav:string = '';

  constructor(
    private router: Router,
  ){
    const self = this;
    self.router.events.subscribe((event) => {
      window.scrollTo(0,0);
      if(event instanceof NavigationEnd && event.url) {
        if(event.url.includes('company')){
          self.curNav = 'company';
          document.title = 'Moyan-公司介绍';
        }else if(event.url.includes('product')){
          self.curNav = 'product';
          document.title = 'Moyan-公司产品';
        }else if(event.url.includes('jobs')){
          self.curNav = 'jobs';
          document.title = 'Moyan-社会招聘';
        }else if(event.url.includes('news')){
          self.curNav = 'news';
          document.title = 'Moyan-最新消息';
        }else if(event.url.includes('contact')){
          self.curNav = 'contact';
          document.title = 'Moyan-联系地址';
        }else if(event.url.includes('campus')){
          self.curNav = 'campus';
          document.title = 'Moyan-校园招聘';
        }else if(event.url.includes('life')){
          self.curNav = 'life';
          document.title = 'Moyan-生活福利';
        }else{
          self.curNav = '';
        }
      }
    });
  }

  toggleNav(): void {
    const self = this;
    self.showNav = !self.showNav;
    if(self.showNav){
      document.body.classList.add('overflow');
    }else{
      document.body.classList.remove('overflow');
    }
  }

  goToRouter(url:string): void {
    const self = this;
    self.showNav = false;
    document.body.classList.remove('overflow');
    self.router.navigate([url]);
  }
}
